import React, { useEffect, useRef, useState } from 'react'
import Swal from 'sweetalert2'
import add from './add'
import addAccessories from './Add/addaccessiors'
import {filter,filter2,filter3,filter4,filter5,setQ,setseries,filter6,filter7} from './other'
import set from './set'
import {  addDoc, collection, doc, getDoc, getDocs, limit, orderBy, query, setDoc, where } from 'firebase/firestore'
import { firestore } from 'Backend/config'
import addcamera from './Add/addcamera'
import addvr from './Add/addvr'
import addp from './Add/addp'
import errormessage from 'components/Main/error'
import { useAuth } from 'Backend/Authentication/Context'
export default function Cctvquotations() {
  var {currentUser} = useAuth()
  var amount = 0;
  var [quoteid,stequoteid] = useState(false)
  var [arr7,setarr7] = useState([])
  var [Accessories,setAccessories] = useState([])
  var Accessories2  = []
  var [viewarr,setviewarr] = useState([])
  var [arr1,setarr1] = useState([])
  var [arr2,setarr2] = useState([])
  var [arr3,setarr3] = useState([])
  var [arr4,setarr4] = useState([])
  var [arr5,setarr5] = useState([])
  var [arr6,setarr6] = useState([])
  var [cam,setcam] = useState([])
  var [hdd,sethdd] = useState([])
  var [userdata,setuserdata] = useState([])
  var camera = []
  var vr2 = []
  var cable2 = []
  var [monitor,setmonitor] = useState([])
  var [cable,setcable] = useState([])
  var monitor2 = []
  var p = []
  var hdd2 = []
  var [p2 ,setp] = useState([])
  var [vr,setvr] = useState([])
  var [series,setseries2] = useState('HD')
  useEffect(()=>{
    var camlen = camera.length
    var arrlen = arr1.length
    if(camlen!==0){
      if(camlen!==arrlen){
        setarr1(camera)
      }
    }
  },[camera])
  useEffect(()=>{
    async function async () {
      currentUser.getdata((data)=>{
        getDoc(doc(firestore,`Users/${data.CreatedByUID}`)).then((data)=>{
          setuserdata(data.data())
        })
      })
    }    
    async()
  },[])
  function adddata(id){
    var arr = []
      arr1.forEach(doc => {
        arr.push({
          Model_Name: doc.Model_Name,
          Price: doc.Price,
          Type:"Camera",
          Description: `Camera: ${doc.Camera_Type}, Resolution: ${doc.Camera_Resolution}, Lens: ${doc.Camera_Lens} ${(doc.Audio ? 'with Audio' : '')}`,
          Company: document.getElementById('Company').value,
          Quantity: doc.Quantity,
          Amount: doc.Amount
        })
      })
      arr.push({
        Model_Name: arr2[0].Model_Name,
        Price: arr2[0].Price,
        Type:"VR",
        Description: `Display Channel : ${arr2[0].Channel} and Audio Channel : ${arr2[0].Audio_Channel}`,
        Company: document.getElementById('Company').value,
        Quantity: arr2[0].Quantity,
        Amount: arr2[0].Amount
      })
      arr.push({
        Model_Name: arr3[0].Model_Name,
        Price: arr3[0].Price,
        Type:"P",
        Description: (series==="HD"?`Power Rating : ${arr3[0].Power_Rating}`:`POE Size : ${arr3[0].POE_Size} , POE Type : ${arr3[0].POE_Type}  ${(arr3[0].Link?(arr3[0].SFP?'with Link and SFP':'with Link'):(arr3[0].SFP?'with SFP':''))}`),
        Company: arr3[0].Company,
        Quantity: arr3[0].Quantity,
        Amount: arr3[0].Amount
      })
      arr.push({
        Model_Name: arr4[0].Model_Name,
        Price: arr4[0].Price,
        Type:"HDD",
        Description: `Terabyte: ${arr4[0].Terabyte}`,
        Company: arr4[0].Company,
        Quantity: arr4[0].Quantity,
        Amount: arr4[0].Amount
      })
      arr.push({
        Model_Name: arr5[0].Model_Name,
        Price: arr5[0].Price,
        Type:"Monitor",
        Description: `Monitor Size: ${arr5[0].Screen_Size} and Supported Cable: ${arr5[0].Supported_Cable}`,
        Company: arr5[0].Company,
        Quantity: arr5[0].Quantity,
        Amount: arr5[0].Amount
      })
      arr.push({
        Model_Name: arr6[0].Model_Name,
        Price: arr6[0].Price,
        Description: `Cable Length: ${arr6[0].Cable_Lenght}`,
        Company: arr6[0].Company,
        Type: "Cable",
        Quantity: arr6[0].Quantity,
        Amount: arr6[0].Amount
      })
      arr7.forEach((doc)=>{
        arr.push({
          Model_Name: doc.Model_Name,
          Price: doc.Price,
          Description: "",
          Type: "Accessories",
          Company: document.getElementById('Company').value,
          Quantity: doc.Quantity,
          Amount: doc.Amount
        })
      })
    setDoc(doc(firestore, `CCTV_Quotations/${id}`), {
      Product: arr,
      Amount: parseInt(arr1.reduce((total, item) => total + item.Amount, 0))+ 
      parseInt(arr7.reduce((total, item) => total + item.Amount, 0) )
      + parseInt(arr2[0].Amount) + parseInt(arr3[0].Amount) + parseInt(arr4[0].Amount) + parseInt(arr5[0].Amount) + parseInt(arr6[0].Amount),
      Count: parseInt(arr1.length + arr7.length + arr2.length + arr3.length + arr4.length + arr5.length + arr6.length),
      ID: id,
      Image: userdata.Image,
      DES: userdata.Description,
      GSTIN: userdata.GSTIN,
      CON1: userdata.Contact_No_1,
      CON2: userdata.Contact_No_2,
      Email: currentUser.user.email,
      URL: "https://firebasestorage.googleapis.com/v0/b/cctv-app-9a35c.appspot.com/o/Quotation%2f"+quoteid+".pdf?alt=media&token=",
      Company_Email: userdata.Email,
      Address: userdata.Address_1 + ' ' + userdata.Address_2,
      Company: userdata.Business_Name,
      CUS_Email: document.getElementById('Email').value,
      CUS_Name: document.getElementById('Name').value,
      CUS_CON: document.getElementById('Phone').value,
      CUS_Address: document.getElementById('Address').value,
      Date: (new Date(document.getElementById('Date').value)).toDateString(),
      Series: series,
      Product_Company: document.getElementById('Company').value
    }).then(() => {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Quotation Added',
        showConfirmButton: false,
        timer: 1500
      })
      stequoteid(id)
      document.querySelector('.add-button').style.display = 'none'
      document.getElementById('Quotation-Features').style.display = ''
      setviewarr(arr)
    })
  }
    const css = `
    .form-control{
          width: 99%;
      }
      body{
          overflow-x: hidden;
      }
      input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
    .form-label{font-weight: bold;}`
    function sendemail(email,object,func){
      addDoc(collection(firestore, "Emails"), {
        Email: email,
        Object: object,
        Template_Id: "5c2b5fd3-2102-41b0-926e-881d4087df2a"
      }).then((snapshot)=>{
        func()
      }).catch((err)=>{
        errormessage(err.message)
      })
    }
    useEffect(() => {
  if(cam.length !== 0){
    var html = ''
    var obj = cam[0]
  document.getElementById("Camera_Type").value = obj.Camera_Type
  document.getElementById("Camera_Resolution").value = obj.Camera_Resolution
  document.getElementById("Camera_Lens").value = obj.Camera_Lens
    cam.forEach(doc => {
      if(doc.Camera_Type === document.getElementById('Camera_Type').value && doc.Camera_Resolution === document.getElementById('Camera_Resolution').value && doc.Camera_Lens === document.getElementById('Camera_Lens').value){
        html += `<option value="${doc.Model_Name}">${doc.Model_Name}</option>`
      }
    })
    if(html !== ''){
      document.getElementById('Camera_Model').innerHTML = html
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'No products found!'
      })
      document.getElementById('Camera_Model').innerHTML = ''
      document.getElementById('Quantity').ariaDisabled = true
    }
  }
}, [cam])
    useEffect(() => {
      if(document.getElementById('Camera_Model').value !== ''){
        var price = cam.filter(doc => doc.Model_Name === document.getElementById('Camera_Model').value)[0].Model_Price
        document.getElementById('Price').innerHTML = price;
      }
    })
useEffect(() => {
  if(document.getElementById('VR_Name').innerHTML !== ''){
    filter3(vr)
  }
},[vr])
function finish(product,func){
  if(product === 'Camera'){
    if(camera.length !== 0){
      document.querySelector(".vr-table-cont").style.display = 'block'
      document.querySelector(".camera-cont").style.display = 'none'
      document.querySelector('.camera-table-cont').querySelectorAll('select').forEach((element)=>{
        element.disabled = true
      })
      document.getElementById('Quantity').disabled = true
      func()
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'No Camera Selected!'
      })
    }
  }else if(product === "Accessories"){
    if(Accessories2.length !== 0){
      func()
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'No Accessories Selected!'
      })
    }
    }
}
useEffect(() => {
  window.addEventListener("beforeunload", (event) => {
    event.preventDefault();
    event.returnValue = "";
    return "";
  },{capture:true});
},[])
function minimize(text) {
  document.querySelector("."+text).style.display = 'none'
  document.querySelector("."+text+"-button").style.display = 'block'
}
function maximize(text) {
  setTimeout(() => {
    minimize(text)
  },4000)
  document.querySelector("."+text).style.display = 'block'
  document.querySelector("."+text+"-button").style.display = 'none'
}
  return (
    <>
    <style>{css}</style>
    <br/>
    <br/>
    <div className='basic-field' style={{marginLeft: '2.5%', marginRight: '2.5%', marginTop: '2.5%', marginBottom: '2.5%', width: '95%'}}>
    <div className='row'>
    <div className='col-sm-3'>
                <label htmlFor="Date" className="form-label">Date</label>
                <input type="date" className="form-control" id='Date' style={{width: '90%',marginLeft: '2.5%'}} defaultValue={new Date().toISOString().split('T')[0]}/>
                </div>
                <div className='col-sm-3'>
                <label htmlFor="Name" className="form-label">Name</label>
                <input type="text" className="form-control" id='Name' style={{width: '90%',marginLeft: '2.5%'}}/>
            </div>
            <div className='col-sm-3'>
                <label htmlFor="Email" className="form-label">Email</label>
                <input type="email" className="form-control" id='Email' style={{width: '90%',marginLeft: '2.5%'}}/>
            </div>
            <div className='col-sm-3'>
                <label htmlFor="Contact" className="form-label">Contact Number</label>
                <input type="tel" className="form-control" id='Phone' style={{width: '90%',marginLeft: '2.5%'}}/>
            </div>

    </div>
    <div className='row'>
    <div className='col-sm-3'>
                <label htmlFor="Address" className="form-label">Address</label>
                <input type="text" id='Address' className="form-control" style={{width: '90%',marginLeft: '2.5%'}}/>
            </div>
            <div className='col-sm-3'>
                <label htmlFor='Camera Series' className="form-label">Camera Series</label>
                <select data-name="Camera_Series" id="Camera_Series" style={{width: '90%',marginLeft: '2.5%'}} className="form-control" onChange={(e) => e.target.value==='IP'?setseries(setseries2,'IP'):setseries(setseries2,'HD')}>
                    <option defaultValue="HD">HD</option>
                    <option defaultValue="IP" defaultChecked>IP</option>
                    </select>
            </div>
            <div className='col-sm-3'>
                <label htmlFor='Company' className="form-label">Company</label>
                <select data-name="Company" id="Company" className="form-control" style={{width: '90%',marginLeft: '2.5%'}}>
                    <option value="CPPLUS">CPPLUS</option>
                    <option value="HIKVISION">HIKVISION</option>
                    <option value="PRAMA">PRAMA</option>
                </select>
            </div>
            <div className='col-sm-3'>
                <br/>
                <button type="submit" className="btn btn-primary" style={{width: '90%',marginLeft: '2.5%'}} id='set' onClick={()=>{set(setcam,()=>{minimize("basic-field");
                })}}>Set</button>
            </div>
    </div>
    </div>
    <button type="button" className="btn btn-primary basic-field-button" style={{width: '45%',marginLeft: '27.5%',display: 'none'}} onClick={()=>{maximize("basic-field")}}>Step 1 :- Customer Details</button>
    <br />
    <div className='camera-table-cont' style={{display: 'none',width: '98%',marginLeft: '1%'}}>
    <table className="table table-hover">
    <thead>
      <tr>
        <th scope="col">Type</th>
        <th scope="col">Resolution</th>
        <th scope="col">Lens</th>
        <th scope="col">Model</th>
        <th scope="col">Price</th>
        <th scope="col">Quantity</th>
        <th scope="col">Amount</th>
        <th scope="col">Delete</th>
      </tr>
      <tr id='camera'>
        <th data-label="Type">
        <select data-name="Camera_Type" id="Camera_Type" className="form-control" onChange={()=>{filter(cam)}}>
                        <option defaultValue="Dome">Dome</option>
                        <option defaultValue="Bullet">Bullet</option>
                    </select>
        </th>
        <th data-label="Resolution">
        <select data-name="Camera_Resolution" id="Camera_Resolution" className="form-control" onChange={()=>{filter(cam)}}>
        <option defaultValue="1MP">1MP</option>
                    <option defaultValue="2MP">2MP</option>
                    <option defaultValue="4MP">4MP</option>
                    <option defaultValue="5MP">5MP</option>
                    <option defaultValue="6MP">6MP</option>
                    <option defaultValue="8MP">8MP</option>
              </select>
        </th>
        <th data-label="Lens">
        <select data-name="Camera_Lens" id="Camera_Lens" className="form-control" defaultValue={"25M"} onChange={()=>{filter(cam)}}>
                    <option defaultValue="10M">10M</option>
                    <option defaultValue="30M">20M</option>
                    <option defaultValue="50M">25M</option>
                    <option defaultValue="20M">30M</option>
                    <option defaultValue="25M">40M</option>
                    <option defaultValue="40M">50M</option>
                    <option defaultValue="80M">80M</option>
                </select>
        </th>
        <th data-label="Model">
        <select data-name="Camera_Model" id="Camera_Model" className="form-control" onChange={()=>{filter2(cam)}}>
          </select>
        </th>
        <th data-label="Price">
          <label htmlFor="Price" className="form-label" id='Price'>Price</label>
          </th>
          <th data-label="Quantity">
            <input id='Quantity' onKeyDown={(e)=>{if(e.key==='Enter'){document.querySelector('.camera-btn').click()}}} type="number" defaultValue={"1"} onChange={(e)=>{setQ(e.target.value)}} className="form-control" style={{width: '90%',marginLeft: '2.5%'}}/>
            </th>
            <th data-label="Amount">
              <label htmlFor="Amount" className="form-label" id='Amount'>Amount</label>
              </th>
              <th data-label="Delete">
              <label htmlFor="Delete" className="form-label" id='Delete'>Delete</label>
              </th>
      </tr>
    </thead>
    <tbody id='tbody'></tbody>
  </table>
  <div className='row camera-cont'>
    <div className='col col-sm-3'>
    <button className='btn btn-primary' onClick={()=>{
      finish("Camera",()=>{
          document.getElementById('tbody').querySelectorAll('tr').forEach((element)=>{
            var Name = element.querySelectorAll('td')[3].textContent
            getDocs(query(collection(firestore,"Products/Camera/Camera"),where("Model_Name","==",`${Name}`),limit(1))).then((snapshot)=>{
              var proxy = []
              getDocs(query(collection(firestore,`Products/${series==="HD"?"DVR":"NVR"}/${series==="HD"?"DVR":"NVR"}`),where("Supported_Cameras","array-contains",{id: snapshot.docs[0].id,name: Name}))).then((snapshot)=>{
                snapshot.docs.forEach((doc)=>{
                  var obj = {id: doc.id,...doc.data()}
                  proxy.push(obj)
                })
              }).then(()=>{
                setvr((old)=>[...old,...proxy])
                minimize('camera-table-cont')
              })
            })
          });
      })
      setTimeout(() => {
        setarr1(camera)
      }, 1000);
    }}>Finish and Add {series==="HD"?"DVR":"NVR"}</button>
    </div>
    <div className='col col-sm-3'>
    <button className='btn btn-primary camera-btn' onClick={()=>{addcamera(camera,()=>{})}}>Add Camera</button>
    </div>
  </div>
    </div>
    <button className='camera-table-cont-button btn btn-primary' onClick={()=>{maximize('camera-table-cont')}} style={{display: 'none',width: '45%',marginLeft: '27.5%',marginBottom: '20px'}} id='camera-table-cont-button'>Step 2 - Camera Details</button>
    <div className='vr-table-cont' style={{display: 'none',width: '98%',marginLeft: '1%'}}>
    <table className="table table-hover">
    <thead>
      <tr>
        <th data-label="Model Name">Model Name</th>
        <th data-label="Model Price">Price</th>
        <th data-label="Channel">Channel</th>
        <th scope='col'>Audio Channel</th>
        <th data-label="Quantity">Quantity</th>
        <th data-label="Amount">Amount</th>
      </tr>
    </thead>
    <tbody className={(series==="HD"?"DVR":"NVR")+"-tbody"}>
      { <tr>
                <td data-label="Model Name"><select data-name="VR_Name" id="VR_Name" className="form-control" onChange={()=>{filter3(vr)}}>
                  {((vr)!==([]))?vr.filter((obj, index, self) =>index === self.findIndex((t) => t.id === obj.id)).map((item) => {
                     return <option key={item.Model_Name} defaultValue={item.Model_Name}>{item.Model_Name}</option>
                  }):null}
                  </select></td>
                <td data-label="Model Price" id='Price2'>Price</td>
                <td data-label="Channel" id='Channel'>Channel</td>
                <td data-label="Display Channel" id='Audio_Channel'>Audio Channel</td>
                <td data-label="Quantity">
                  <select type='number' id='Quantity2' defaultValue="1"  onChange={(e)=>{if(e.target.value==='0'){
                    e.target.value='1'}else{
                      document.getElementById('Amount2').textContent=((parseInt(e.target.value))*(parseInt(document.getElementById('Price2').innerHTML)))
                      }}} className="form-control" style={{width: '90%',marginLeft: '2.5%'}}>
                    <option defaultValue="1">1</option>
                    <option defaultValue="2">2</option>
                    <option defaultValue="3">3</option>
                    </select>
                </td>
                <td data-label="Amount" id='Amount2'>Amount</td>
              </tr>}
    </tbody>
  </table>
  <div className='row vr-cont'>
    <div className='col col-sm-3'>
    <button className='btn btn-primary vr-btn' onClick={()=>{addvr(vr2,(series==="HD"?"DVR":"NVR"),(arr234)=>{
      vr2 = arr234
      setarr2(vr2)
      minimize('vr-table-cont')
      getDocs(
        query(
          collection(firestore,`Products/${series==="HD"?"PSU":"POE"}/${series==="HD"?"PSU":"POE"}`))).then((snapshot)=>{
            var html =''
        var arr = []
        snapshot.docs.forEach((doc)=>{
          var obj = {id: doc.id,...doc.data()};arr.push(obj)
          html += '<option defaultValue="'+doc.data().Model_Name+'">'+doc.data().Model_Name+'</option>'
        })
        document.getElementById('Model_Name3').innerHTML = html
        document.getElementById('Price3').innerHTML = arr[0].Model_Price
        document.getElementById('Amount3').innerHTML = arr[0].Model_Price
        if(series==="HD"){
          document.getElementById('Power_Rating').innerHTML = arr[0].Power_Rating
          document.getElementById('Company2').innerHTML = arr[0].Company
        }else{
          var obj = arr[0]
          document.getElementById('Company2').innerHTML = obj.Company
      document.getElementById('POE_Size').innerHTML = obj.POE_Size
      document.getElementById('POE_Type').innerHTML = obj.POE_Type
      document.getElementById('Link').innerHTML = (obj.Link?'<i className="fa-solid fa-check"></i>':'<i className="bi bi-x-lg"></i>')
      document.getElementById('SFP').innerHTML = (obj.SFP?'<i className="fa-solid fa-check"></i>':'<i className="bi bi-x-lg"></i>')
        }
        setp(arr)
      })
      document.querySelector('.vr-table-cont').style.display = 'none'
      document.querySelector('.vr-btn').style.display = 'none'
      document.querySelector('.p-table-cont').style.display = 'block'
})}}>Save and Add {series==="HD"?"PSU":"POE"}</button>
    </div>
  </div>
    </div>
    <button className='vr-table-cont-button btn btn-primary' onClick={()=>{maximize('vr-table-cont')}} style={{display: 'none',width: '45%',marginLeft: '27.5%',marginBottom: '20px'}} id='vr-table-cont-button'>Step 3 - {series==="HD"?"DVR":"NVR"} Details</button>
    <div className="p-table-cont" style={{display: 'none',width: '98%',marginLeft: '1%'}}>
    <table className="table table-hover">
    <thead>
      <tr>
        <th data-label="Model Name">Model Name</th>
        <th data-label="Model Price">Price</th>
        <th data-label="Company">Company</th>
        {series==="IP"?<th data-label="POE Size">POE Size</th>:null}
        {series==="IP"?<th data-label="POE Type">POE Type</th>:null}
        {series==="IP"?<th data-label="Link">Link</th>:null}
        {series==="IP"?<th data-label="SFP">SFP</th>:null}
        {series==="HD"?<th data-label="Power Rating">Power Rating</th>:null}
        <th data-label="Quantity">Quantity</th>
        <th data-label="Amount">Amount</th>
        </tr>
    </thead>
    <tbody className={(series==="HD"?"PSU":"POE")+"-tbody"}>
        <tr>
          <td data-label='Model_Name'>
            <select className='form-control' id='Model_Name3' onChange={(e)=>{filter4(series==="HD"?"PSU":"POE",p2,e.target.value)}}>
              
            </select>
          </td>
          <td data-label="Model Price">
            <label id="Price3" className="form-label">Price</label>
          </td>
          <td data-label="Company">
            <label id="Company2" className="form-label">Company</label>
          </td>
          {series==="IP"?<td data-label="POE Size">
            <label id="POE_Size" className="form-label">POE Size</label>
          </td>:null}
          {series==="IP"?<td data-label="POE Type">
            <label id="POE_Type" className="form-label">POE Type</label>
          </td>:null}
          {series==="IP"?<td data-label="Link">
            <label id="Link" className="form-label">Link</label>
          </td>:null}
          {series==="IP"?<td data-label="SFP">
            <label id="SFP" className="form-label">SFP</label>
          </td>:null}
          {series==="HD"?<td>
            <label  className="form-label" id='Power_Rating'>Power Rating</label>
          </td>:null}
          <td data-label="Quantity">
            <select type='number' defaultValue="1" id='Quantity3' className='form-control'  onChange={(e)=>{
              if(e.target.value==='0'){
              e.target.value='1'
            }else{
              document.getElementById('Amount3').textContent=((parseInt(e.target.value))*(parseInt(document.getElementById('Price3').textContent)))
            }}}>
              <option defaultValue="1">1</option>
              <option defaultValue="2">2</option>
              <option defaultValue="3">3</option>
              <option defaultValue="4">4</option>
            </select>
          </td>
          <td data-label="Amount" className="form-label" id='Amount3'>Amount</td>
        </tr>
    </tbody>
  </table>
  <br />
  <div className="row">
    <div className="col-sm-3">
      <button className='btn btn-primary' id='p-btn' style={{width: '100%'}} onClick={()=>{
          addp((series==="HD"?"PSU":"POE"),p,(arr234)=>{
            p = arr234
            setarr3(arr234)
            minimize('p-table-cont')
            getDocs(query(collection(firestore,'Products/HDD/HDD'),orderBy('Terabyte','asc'))).then((snapshot)=>{
              var set = []
              snapshot.docs.forEach((doc)=>{
                var data = doc.data()
                data.id = doc.id
                set.push(data)
              })
              document.querySelector('.hdd-table-cont').style.display = 'block'
              document.getElementById('Model_Name4').defaultValue = snapshot.docs[0].data().Model_Name
              document.getElementById('Price4').textContent = snapshot.docs[0].data().Model_Price
              document.getElementById('Company3').textContent = snapshot.docs[0].data().Company
              document.getElementById('Terabyte').innerHTML = snapshot.docs[0].data().Terabyte
              document.getElementById('Amount4').textContent = snapshot.docs[0].data().Model_Price
              sethdd(set)
            })
          })
      }}>Finish and Add Hard Disk</button>
    </div>
  </div>
    </div>
    <button className='btn btn-primary p-table-cont-button' style={{display: 'none',width: '45%',marginLeft: '27.5%',marginBottom: '20px'}} onClick={()=>{maximize('p-table-cont')}}>
      Step 4 - {series==="HD"?"PSU":"POE"} Details
    </button>
    <div className="hdd-table-cont" style={{width: '98%',marginLeft: '1%',marginBottom: '20px',display: 'none'}}>
      <table>
        <thead>
          <tr>
            <th data-label="Model Name">Model Name</th>
            <th data-label="Model Price">Price</th>
            <th data-label="Company">Company</th>
            <th data-label="Terabyte">Terabyte</th>
            <th data-label="Quantity">Quantity</th>
            <th data-label="Amount">Amount</th>
          </tr>
        </thead>
        <tbody className="hdd-tbody">
          <tr>
            <td data-label='Model_Name'>
              <select id="Model_Name4" className='form-control' onChange={(e)=>{filter5("HDD",e.target.value,hdd)}}>
                {(hdd.length)!==(0)?hdd.map((doc)=>{
                  return <option key={doc.id} value={doc.Model_Name}>{doc.Model_Name}</option>
                }):null}
              </select>
            </td>
            <td data-label="Model Price">
              <label id="Price4" className="form-label">Price</label>
            </td>
            <td data-label="Company">
              <label id="Company3" className="form-label">Company</label>
            </td>
            <td data-label="Terabyte">
              <label id="Terabyte" className="form-label">Terabyte</label>
            </td>
            <td data-label="Quantity">
              <select type='number' defaultValue="1" id='Quantity4' className='form-control'  onChange={(e)=>{
                if(e.target.value==='0'){
                e.target.value='1'
              }else{
                document.getElementById('Amount4').textContent=((parseInt(e.target.value))*(parseInt(document.getElementById('Price4').textContent)))
              }}}>
                <option defaultValue="1">1</option>
                <option defaultValue="2">2</option>
              </select>
            </td>
            <td data-label="Amount" className="form-label" id='Amount4'>Amount</td>
          </tr>
        </tbody>
      </table>
      <br />
      <div className="row hdd-cont">
        <div className="col col-sm-3">
          <button className='btn btn-primary' id='h-btn' style={{width: '100%'}} onClick={()=>{
            add(hdd2,"HDD",(arr)=>{
              hdd2 = arr
              setarr4(arr)
              minimize('hdd-table-cont')
              document.querySelector('.hdd-cont').style.display = 'none'
              getDocs(query(collection(firestore,'Products/Monitor/Monitor'),orderBy('Monitor_Size','asc'))).then((snapshot)=>{
                var arr = []
                snapshot.docs.forEach((doc)=>{
                  var data = doc.data()
                  data.id = doc.id
                  arr.push(data)
                })
                setmonitor(arr)
                document.getElementById('Model_Name5').defaultValue = snapshot.docs[0].data().Model_Name
                document.getElementById('Price5').textContent = snapshot.docs[0].data().Model_Price
                document.getElementById('Company4').textContent = snapshot.docs[0].data().Company
                document.getElementById('Supported_Cables').innerHTML = snapshot.docs[0].data().Supported_Cable
                document.getElementById('Screen_Size').innerHTML = snapshot.docs[0].data().Monitor_Size
                document.getElementById('Amount5').textContent = snapshot.docs[0].data().Model_Price
                document.querySelector('.monitor-table-cont').style.display = 'block'
                document.querySelector('.hdd-table-cont').querySelectorAll('select').forEach((element)=>{
                  element.disabled = true
                })
              })
            })
          }}>Finish and Add Monitor</button>
        </div>
      </div>
    </div>
    <button className='btn btn-primary hdd-table-cont-button' style={{display: 'none',width: '45%',marginLeft: '27.5%',marginBottom: '20px'}} onClick={()=>{maximize('hdd-table-cont')}}>
      Step 5 - Hard Disk Details
    </button>
<div className="monitor-table-cont" style={{width: '98%',marginLeft: '1%',marginBottom: '20px',display: 'none'}}>
    <table>
      <thead>
        <tr>
          <th data-label="Model Name">Model Name</th>
          <th data-label="Model Price">Price</th>
          <th data-label="Company">Company</th>
          <th data-label="Screen_Size">Screen Size</th>
          <th data-label="Supported Cables">Supported Cables</th>
          <th data-label="Quantity">Quantity</th>
          <th data-label="Amount">Amount</th>
        </tr>
      </thead>
      <tbody className="monitor-tbody">
        <tr>
          <td data-label='Model_Name'>
            <select id="Model_Name5" className='form-control' onChange={(e)=>{filter5("Monitor",e.target.value,monitor)}}>
              {(monitor.length)!==(0)?monitor.map((doc)=>{
                return <option key={doc.id} value={doc.Model_Name}>{doc.Model_Name}</option>
              }):null}
            </select>
          </td>
          <td data-label="Model Price">
            <label id="Price5" className="form-label">Price</label>
          </td>
          <td data-label="Company">
            <label id="Company4" className="form-label">Company</label>
          </td>
          <td data-label="Screen_Size">
            <label id="Screen_Size" className="form-label">Screen Size</label>
          </td>
          <td data-label="Supported Cables">
            <label id="Supported_Cables" className="form-label">Supported Cables</label>
          </td>
          <td data-label="Quantity">
            <select type='number' defaultValue="1" id='Quantity5' className='form-control'  onChange={(e)=>{
              if(e.target.value==='0'){
              e.target.value='1'
            }else{
              document.getElementById('Amount5').textContent=((parseInt(e.target.value))*(parseInt(document.getElementById('Price5').textContent)))
            }}}>
              <option defaultValue="1">1</option>
              <option defaultValue="2">2</option>
            </select>
          </td>
          <td data-label="Amount" className="form-label" id='Amount5'>Amount</td>
        </tr>
      </tbody>
    </table>
    <br />
    <div className="row monitor-cont">
      <div className="col col-sm-3">
        <button className='btn btn-primary' id='moni-btn' style={{width: '100%'}} onClick={()=>{
          add(monitor2,"Monitor",(arr)=>{
            monitor2 = arr
            setarr5(arr)
            minimize('monitor-table-cont')
            document.querySelector('.monitor-cont').style.display = 'none'
            document.querySelector('.monitor-table-cont').querySelectorAll('select').forEach((element)=>{
              element.disabled = true
            })
            getDocs(query(collection(firestore,"Products/Cable/Cable"),where("Cable_Series","==",document.getElementById('Camera_Series').value))).then((snapshot)=>{
              var arr = []
              snapshot.docs.forEach((doc)=>{
                var data = doc.data()
                data.id = doc.id
                arr.push(data)
              })
              setcable(arr)
              document.querySelector('.cable-table-cont').style.display = 'block'
              document.getElementById('Model_Name6').defaultValue = snapshot.docs[0].data().Model_Name
              document.getElementById('Price6').textContent = snapshot.docs[0].data().Model_Price
              document.getElementById('Company5').textContent = snapshot.docs[0].data().Company
              document.getElementById('Cable_Lenght').textContent = snapshot.docs[0].data().Cable_Lenght
              document.getElementById('Amount6').textContent = snapshot.docs[0].data().Model_Price
            })
          })
        }}>Finish and Add Cable</button>
      </div>
    </div>
  </div>
  <button className='btn btn-primary monitor-table-cont-button' style={{display: 'none',width: '45%',marginLeft: '27.5%',marginBottom: '20px'}} onClick={()=>{maximize('monitor-table-cont')}}>
    Step 6 - Monitor Details
  </button>
  <div className="cable-table-cont" style={{width: '98%',marginLeft: '1%',marginBottom: '20px',display: 'none'}}>
    <table>
      <thead>
        <tr>
          <th data-label="Model Name">Model Name</th>
          <th data-label="Model Price">Price</th>
          <th data-label="Company">Company</th>
          <th data-label="Cable Lenght">Cable Lenght</th>
          <th data-label="Quantity">Quantity</th>
          <th data-label="Amount">Amount</th>
        </tr>
      </thead>
      <tbody className="cable-tbody">
        <tr>
          <td data-label='Model_Name'>
            <select id="Model_Name6" className='form-control' onChange={(e)=>{filter6(e.target.value,cable)}}>
              {(cable.length)!==(0)?cable.map((doc)=>{
                return <option key={doc.id} value={doc.Model_Name}>{doc.Model_Name}</option>
              }):null}
            </select>
          </td>
          <td data-label="Model Price">
            <label id="Price6" className="form-label">Price</label>
          </td>
          <td data-label="Company">
            <label id="Company5" className="form-label">Company</label>
          </td>
          <td data-label="Cable Lenght">
            <label id="Cable_Lenght" className="form-label">Cable Lenght</label>
          </td>
          <td data-label="Quantity">
            <select type='number' defaultValue="1" id='Quantity6' className='form-control'  onChange={(e)=>{
              if(e.target.value==='0'){
              e.target.value='1'
            }else{
              document.getElementById('Amount6').textContent=((parseInt(e.target.value))*(parseInt(document.getElementById('Price6').textContent)))
            }}}>
              <option defaultValue="1">1</option>
              <option defaultValue="2">2</option>
              <option defaultValue="3">3</option>
              <option defaultValue="4">4</option>
              <option defaultValue="5">5</option>
              <option defaultValue="6">6</option>
              <option defaultValue="7">7</option>
              <option defaultValue="8">8</option>
              <option defaultValue="9">9</option>
              <option defaultValue="10">10</option>
            </select>
          </td>
          <td data-label="Amount" className="form-label" id='Amount6'>Amount</td>
        </tr>
      </tbody>
    </table>
    <br />
    <div className="row cable-cont">
      <div className="col col-sm-3">
        <button className='btn btn-primary' id='cable-btn' style={{width: '100%'}} onClick={()=>{
          add(cable2,"Cable",(arr)=>{
            cable2 = arr
            setarr6(arr)
            getDocs(query(collection(firestore,`Products/Accessories/Accessories`))).then(
              (snapshot)=>{
                var arr = []
                snapshot.docs.forEach((doc)=>{
                  arr.push({id: doc.id,...doc.data()})
                })
                document.getElementById('Price7').innerHTML = snapshot.docs[0]
                setAccessories(arr)
              }
            )
            minimize('cable-table-cont')
            document.querySelector('.cable-cont').style.display = 'none'
            document.querySelector('.cable-table-cont').querySelectorAll('select').forEach((element)=>{
              element.disabled = true
            })
            document.querySelector('.Accessories-table-cont').style.display = "block"
          })
        }}>Finish and Add Accessories</button>
      </div>
    </div>
  </div>
  <button className='btn btn-primary cable-table-cont-button' style={{display: 'none',width: '45%',marginLeft: '27.5%',marginBottom: '20px'}} onClick={()=>{maximize('cable-table-cont')}}>
    Step 7 - Cable Details
  </button>
  <div className="Accessories-table-cont" style={{width: '98%',marginLeft: '1%',marginBottom: '20px',display: 'none'}}>
  <table>
    <thead>
      <tr>
        <th data-label="Model Name">Model Name</th>
        <th data-label="Model Price">Price</th>
        <th data-label="Quantity">Quantity</th>
        <th data-label="Amount">Amount</th>
        <th scope="col">Delete</th>
      </tr>
    </thead>
    <tbody className="Accessories-tbody">
      <tr>
        <td data-label='Model_Name'>
          <select id="Model_Name7" className='form-control' onChange={(e)=>{filter7(e.target.value,Accessories)}}>
            {(Accessories.length)!==(0)?Accessories.map((doc)=>{
              return <option key={doc.id} value={doc.Model_Name}>{doc.Model_Name}</option>
            }):null}
          </select>
        </td>
        <td data-label="Model Price">
          <input id="Price7" className="form-control" onInput={(e)=>{
            if(e.target.value==='0'){
            e.target.value='1'
          }else{
            document.getElementById('Amount7').textContent=((parseInt(e.target.value))*(parseInt(document.getElementById('Quantity7').value)))
          }}}></input>
        </td>
        <td data-label="Quantity">
          <input type='number' defaultValue="1" id='Quantity7' className='form-control'  onInput={(e)=>{
            if(e.target.value==='0'){
            e.target.value='1'
          }else{
            document.getElementById('Amount7').textContent=((parseInt(e.target.value))*(parseInt(document.getElementById('Price7').value)))
          }}}></input>
        </td>
        <td data-label="Amount" className="form-label" id='Amount7'>Amount</td>
        <td data-label="Delete">Delete</td>
      </tr>
    </tbody>
  </table>
  <br />
  <div className="row Accessories-cont">
    <div className="col col-sm-3">
      <button className='btn btn-primary' id='Accessories-btn' style={{width: '100%'}} onClick={()=>{
        finish("Accessories",()=>{
          setarr7(Accessories2)
          minimize('Accessories-table-cont')
          document.querySelector('.Accessories-cont').style.display = 'none'
          document.querySelector('.Accessories-table-cont').querySelectorAll('select').forEach((element)=>{
            element.disabled = true
          })
          document.querySelectorAll('input').forEach((element)=>{
            element.disabled = true
          })
          document.querySelector('.add-button').style.display = "block"
        })
      }}>Finish</button>
    </div>
    <div className="col col-sm-3">
    <button className='btn btn-primary' id='Accessories-add-btn' onClick={()=>{addAccessories(Accessories2,()=>{})}}>Add Accessories</button>
    </div>
  </div>
</div>
<button className='btn btn-primary Accessories-table-cont-button' style={{display: 'none',width: '45%',marginLeft: '27.5%',marginBottom: '20px'}} onClick={()=>{maximize('Accessories-table-cont')}}>
  Step 8 - Accessories Details
</button>
  <button className='btn btn-primary add-button' style={{display: 'none',width: '25%',marginLeft: '37.5%',marginBottom: '20px'}} onClick={()=>{
    var num = Math.floor(Math.random() * 100000000000000000001)
    getDoc(doc(firestore,"CCTV_Quotations/"+num)).then((doc)=>{
      if(!doc.exists()){
        adddata(num)
        let timerInterval;
        Swal.fire({
          title: "Creating Quotation",
          html: "Please wait... for <b></b> milliseconds.",
          timer: 3000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            const timer = Swal.getPopup().querySelector("b");
            timerInterval = setInterval(() => {
              timer.textContent = `${Swal.getTimerLeft()}`;
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
          }
        }).then((result) => {
          Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Quotation created successfully!',
          showConfirmButton: false,
          timer: 1000
          })
        });
        return
      }
      else{
        var num2 = Math.floor(Math.random() * 100000000000000000001)
        getDoc(doc(firestore,"CCTV_Quotations/"+num2)).then((doc)=>{
          if(!doc.exists()){
            adddata(num2)
            let timerInterval;
Swal.fire({
  title: "Creating Quotation",
  html: "Please wait... for <b></b> milliseconds.",
  timer: 3000,
  timerProgressBar: true,
  didOpen: () => {
    Swal.showLoading();
    const timer = Swal.getPopup().querySelector("b");
    timerInterval = setInterval(() => {
      timer.textContent = `${Swal.getTimerLeft()}`;
    }, 100);
  },
  willClose: () => {
    clearInterval(timerInterval);
  }
}).then((result) => {
  Swal.fire({
  icon: 'success',
  title: 'Success',
  text: 'Quotation created successfully!',
  showConfirmButton: false,
  timer: 1000
  })
});
            return
          }
          else{
            errormessage('Something went wrong! Please try again later')
          }
        })
      }
    })
  }}>
    Complete
  </button>
  <div id="Quotation-Features" className='row' style={{display: 'none'}}>
    <div className="col-sm-3 ms-2">
    <button className="btn btn-primary" id="Quotation-Features-View" onClick={(e)=>{
      if(e.target.innerHTML === "Hide Quotation"){
        e.target.innerHTML = "View Quotation"
        document.getElementById("Quotation-Features-View-Table").style.display = 'none'
      }else{
        e.target.innerHTML = "Hide Quotation"
        document.getElementById("Quotation-Features-View-Table").style.display = 'block'
      }
    }}>View Quotation</button>
    </div>
    <div className="col-sm-3">
      <button className="btn btn-primary" onClick={(e)=>{sendemail(document.getElementById('Email').value,
      {Name: document.getElementById('Name').value,url:"https://firebasestorage.googleapis.com/v0/b/cctv-app-9a35c.appspot.com/o/Quotation%2f"+quoteid+".pdf?alt=media&token="}
      ,function() {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Email Sent Successfully',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          e.disabled = true
        })
      })}}>Send Email</button>
    </div>
  </div>
  <br />
  <div id="Quotation-Features-View-Table" style={{display: 'none',width: '98%',marginLeft: '1%'}}>
  <table>
    <thead>
      <tr>
        <th scope='col'>Sno</th>
        <th scope='col'>Company</th>
        <th scope='col'>Model_Name</th>
        <th scope='col'>Description</th>
        <th scope='col'>Price</th>
        <th scope='col'>Quantity</th>
        <th scope='col'>Amount</th>
      </tr>
    </thead>
    <tbody id="Quotation-Features-View-Table-Body">
      {(viewarr.length!==0)?viewarr.map((element,index)=>{
        amount += parseInt(element.Amount)
        console.log(viewarr)
        return(
          <tr key={index}>
            <td>{index+1}</td>
            <td>{element.Company}</td>
            <td>{element.Model_Name}</td>
            <td>{element.Description}</td>
            <td>{element.Price}</td>
            <td>{element.Quantity}</td>
            <td>{element.Amount}</td>
          </tr>
        )
      }):null}
      {(viewarr.length!==0)?
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td><b>Total :- </b></td>
        <td>{amount}</td>
      </tr>
      :null}
    </tbody>
  </table>
  <br />
  <br />
  </div>
  <div style={{position: "fixed",right: "10px",bottom: "10px"}}>
    <button className='btn btn-primary' onClick={()=>{
      history.back()
    }}>Back to Dashboard</button>
  </div>
    </>
  )
}