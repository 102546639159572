import { useAuth } from 'Backend/Authentication/Context'
import { firestore } from 'Backend/config'
import errormessage from 'components/Main/error'
import { addDoc, collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'

export default function Employees() {
  document.title = "CCTV Quote - Employees"
  const [users,setusers] = useState(undefined)
  const {currentUser} = useAuth()
  const [a1,seta1] = useState(undefined)
  const [a2,seta2] = useState(undefined)
  const [subscription,setsubscription] = useState(undefined)
  const [bn,setbn] = useState(undefined)
  useEffect(() => {
    getDocs(query(collection(firestore,"Users"),where("Access","==","Employee"),where("CreatedByUID","==",currentUser.user.uid))).then((data)=>{
      setusers(data.docs)
    })
    currentUser.getdata((data)=>{      
      seta1(data.Address_1)
      seta2(data.Address_2)
      setbn(data.Business_Name)
      setsubscription(data.Subscription.toDate())
    })
  },[])
  const setstatus = (uid,status) => {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, change it!'
    }).then((result) => {
      if (result.isConfirmed) {
        updateDoc(doc(firestore, `Users/${uid}`), {
          Enabled: status?false:true
        })
        .then(() => {
          document.querySelector(".change-status-btn").innerHTML = status?'Enable':'Disable'
          document.querySelector(".tbody").querySelectorAll("tr").forEach((element)=>{
              element.querySelector(".enabled-span").innerHTML = status?'<span class="text-danger">Disabled</span>':'<span class="text-success">Enabled</span>'
          })
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Status changed successfully!',
            timer: 3000,
            showConfirmButton: false
          })
        })
      }
    })
  }
  function addemploy(){
    Swal.fire({
      title: 'Add Employee',
      html: `<form>
      <div class="mb-3">
        <label for="name" class="form-label">Name</label>
        <input type="text" class="form-control" id="name" />
      </div>
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">Email address</label>
          <input type="email" class="form-control" id="Email" aria-describedby="emailHelp" />
        </div>
        <div class="mb-3">
          <label for="exampleInputPassword1" class="form-label">Password</label>
          <input type="password" class="form-control" id="Password" />
        </div>
        <div class="mb-3">
          <label for="phone" class="form-label">Phone</label>
          <input type="number" class="form-control" id="phone" />
        </div>
      </form>`,
      confirmButtonText: 'Add Employee',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      focusConfirm: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const email = document.getElementById('Email').value
        const password = document.getElementById('Password').value
        const phone = document.getElementById('phone').value
        const name = document.getElementById('name').value
        console.log({email,password,phone,name})
        if((/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email))
          &&(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(password))){  
            addDoc(collection(firestore, "Employees"),{
              Email: email,
              Employee_Name: name,
              Password: password,
              Subscription: subscription,
              Createdby: currentUser.user.email,
              CreatedByUID: currentUser.user.uid,
              Contact_No: phone,
              Access: "Employee",
              Enabled: true,
              Creation_Time: new Date(),
              Last_Login: new Date()
            }).then(() => {
              Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Employee added successfully!',
                timer: 3000,
                showConfirmButton: false
              })
            }).catch((error) => {
              console.error("Error adding employee: ", error);
            });
        }else{
          errormessage("Invalid Email or Password")
        }
      }
    }).catch((error) => {
      errormessage(error.message)
    });
  }
  const viewmore = (data) => {
    Swal.fire({
      title: data.Business_Name,
      html: `<p>Email : ${data.Email}</p><br/><p>Phone : ${data.Contact_No}</p><br/><p>Created : ${data.Creation_Time.toDate().toDateString()}</p><br/><p>Last Login : ${data.Last_Login.toDate().toDateString()}</p>
    <br/><p>Password : <button class="btn btn-danger" onclick="if(this.textContent==='View Password'){this.innerHTML = '${data.Password}';this.disabled = true;setTimeout(()=>{this.innerHTML = 'View Password';this.disabled = false},3000)}else{this.innerHTML = 'View Password'}">View Password</button></p>`,
    })
  }
  return (
    <div className='pr-6'>
      <br />
      <center><h1 className='h3'>Employees</h1></center>
      <br />
      <table>
        <thead>
          <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Status</th>
          <th>Enable/Disable</th>
          <th>View More</th>
          </tr>
        </thead>
        <tbody className='tbody'>
        {users && users.map((user)=>{
        return(<tr key={user.id}>
          <td>{user.data().Employee_Name}</td>
        <td>{user.data().Email}</td>
        <td className='enabled-span'>{user.data().Enabled?<span className="text-success">Enabled</span>:<span className="text-danger">Disabled</span>}</td>
        <td><button className='btn btn-primary change-status-btn' onClick={() => {setstatus(user.id,user.data().Enabled,)}}>{user.data().Enabled?"Disable":"Enable"}</button></td>
        <td><button className='btn btn-primary' onClick={() => {viewmore({...user.data(),id:user.id})}}>View More</button></td>
        </tr>)
      })}
        </tbody>
      </table>
      <div style={{position:'fixed',right:'20px',bottom:'20px'}}>
        <button className='btn btn-primary' onClick={()=>{addemploy()}}>Add Employee</button>
      </div>
    </div>
  )
}